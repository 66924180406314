import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import {container} from "react-bootstrap"
import Layout from "../../components/layout"

export default function PopTheBalloon({data}) {
  return (
    <Layout>

      <Helmet>
          <meta charSet="utf-8" />
          <title>Data policy | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/about" />
      </Helmet>



      <h3>Data Policy</h3>

<p>Your privacy is very important to us, and we take it seriously. Please read our Data Policy below and let us know if you have any questions or other feedback regarding it.</p>



<strong>ADVERTISING</strong>

<p>Many of our apps use advertising to provide the content free of charge. When you use these apps your device automatically sends certain information to our advertising partners Admob and Kidoz. This information includes, for example, the name of the app, the device type, and the advertising ID.</p>

<p>The information gathered is solely used to provide our advertising partners with the bare minimum of information they need to provide their services and combat click fraud. Within the EU, we serve non-personalized ads only.</p>

<strong>ADMOB</strong>
<p>AdMob is an advertising service provided by AdMob Google Inc. Please read this overview of how they use information from sites or apps that use their services.</p>

<p>Personal Data collected: Unique device identifiers for advertising. For example, Google Advertiser ID or Apple ID For Advertising.</p>

<p>Place of processing: United States</p>

<p><a href="https://www.google.com/policies/technologies/ads/" target="_blank">Privacy Policy</a> | <a href="https://www.google.com/settings/ads" target="_blank">Opt Out</a></p>

{/*
<strong>KIDOZ</strong>
<p>Kidoz is an advertising service provided by Kidoz Ltd.</p>

<p>Personal Data collected: Unique device identifiers for advertising. For example, Google Advertiser ID or Apple ID For Advertising.</p>

<p>Place of processing: Israel</p>

<p><a href="http://kidoz.net/website-and-kidoz-sdk-privacy-policy/" target="_blank">Privacy Policy</a> | <a href="mailto:support@kidoz.com" target="_blank">Opt Out</a></p>
*/}


<strong>ANALYTICS</strong>
<p>Most of our apps use analytics (usage data) in order to understand how users engage with our apps and how to improve them. When you use these apps your device automatically sends certain information to our analytics partner Google Analytics. The information gathered information includes, for example, the device type, the session length, and interactions with various user interface elements (buttons) in the game.</p>

<p>The analytics data collected is anonymous and is not personal data. At first startup of an app we generate a random unique identifier which does not contain any personal information, and cannot be used to uncover any such information</p>



<strong>OPTING OUT</strong>
<p>If you no longer want us to use your IP address and mobile advertising ID then you need to either pay to remove advertisements or stop using the app.</p>

<p>For more choices please follow the “Opt Out” link for the respective service above.</p>



<strong>OWNER AND DATA CONTROLLER</strong>
<p>CheePlus Studio, Auckland, New Zealand</p>

<p>Contact e-mail: cheeplus@protonmail.ch</p>



<strong>QUESTIONS CONCERNING OUR DATA CONSENT POLICY</strong>

<p>If you have any question concerning our usage of  personal data please contact us.</p>


<p>===LAST UPDATED===</p>

<p>The Data Consent was last updated on 31/08/2020.</p>

</Layout>
)
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
